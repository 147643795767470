export const environment = {
    production: true,
    appPage: "/SitePages/App.aspx/",
    proxyTenant: "https://tgpweb.sharepoint.com",
    webRelativeUrl: "/sites/sgd",
    proxyUrl: "https://tgpweb.sharepoint.com/sites/sgd",
    clientId: "afbcc639-3857-487c-948c-91c0b6c957ae",
    authority: "https://login.microsoftonline.com/6a9934b6-3b37-4b45-b3e9-26c04b9d50d1",
    redirectUri: "https://tgpweb.sharepoint.com/sites/sgd/",
    apiUrl: "https://app-tgp-mesapartes-be-prd-bs-01.azurewebsites.net/",
    apiAppId: "api://983ffc6c-2d68-4f2b-bce2-5aee2b04943b/restApi.access",
    apiUrlBackEnd: "https://app-tgp-mesapartes-be-prd-bs-01.azurewebsites.net",
    recaptchaSiteKey: '6Lcy6v4oAAAAAD4pPOPRKVkBmcKVq1ba_SIQ9TRA',
    abreviaturaEmpresa: "TGP",
    urlLogo: "https://stgtgpprdbs01.blob.core.windows.net/imagenes/logo-tgp.png",

    getRutaBase() {
      return `${environment.proxyUrl}`;
    },

    getRutaBaseApp() {
      let rutaBase = "";

      if (this.production) {
        rutaBase = environment.proxyTenant + environment.webRelativeUrl + environment.appPage;
      } else {
        rutaBase = environment.proxyTenant + "/";
      }

      return rutaBase;
    },
  };
